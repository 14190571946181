import { useLazyQuery, useQuery } from "@apollo/client";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Constants from "../../commons/Constants";
import DescriptionList from "../../Components/DescriptionList/DescriptionList";
import SelectNative from "../../Components/Form/SelectNative";
import Spinner from "../../Components/Spinner";
import { toastError } from "../../Components/Utils/Utils";
import { useP } from "../../services/i18n";
import { GET_PRESTATIONS } from "../Prestations/queries";
import LibelleExportGSM from "./LibelleExportGSM";
import { TELECHARGER_EXPORT_CDR_GSM_PRESTA, TELECHARGER_EXPORT_CONSO, TELECHARGER_EXPORT_PARC_GSM } from "./queries";


type DownloadButtonType = {
  onClick: () => void,
  loading: boolean
}
function DownloadButton({ onClick, loading }: DownloadButtonType) {

  return <>
    {loading
      ? <div className="flex items-center justify-end p-1.5"><Spinner color="gray" size="h-4 w-4" borderWidth="xs" /></div>
      : <div className="flex items-center justify-end"><button type="button" className="flex items-center justify-end hover:bg-gray-100 rounded-md p-1.5 cursor-pointer" onClick={onClick}>
        <ArrowDownTrayIcon className="h-4 w-4 text-gray-900" />
      </button></div>
    }
  </>
}


type DownloadButtonConsoType = {
  onClick: () => void,
  loading: boolean,
  onSelectYearMonth: (value: string) => void
}
function DownloadButtonConso({ onClick, loading, onSelectYearMonth }: DownloadButtonConsoType) {

  const curDate = dayjs();
  function getPrevMonth(_, i) {
    const prev = new Date(curDate.year(), curDate.month() - i, 0)
    return {
      code: [dayjs(prev).format('YYYY'), dayjs(prev).format('MM')].join(''),
      libelle: [dayjs(prev).format('MM'), dayjs(prev).format('YYYY')].join('/')
    }
  }
  const yearsMonths = Array.from({ length: 24 }, getPrevMonth); // On récupère les 24 derniers mois

  const selectYearsMonths = (value) => {
    onSelectYearMonth(value);
  }

  return <>
    <div className="flex items-center justify-end">
      <SelectNative
        className="w-[100px] text-xs border border-gray-300 rounded-md py-1 pl-1 mr-1 pr-0"
        selectDatas={yearsMonths} onSelect={selectYearsMonths}
        defaultValue={yearsMonths[0].code}
      />
      <DownloadButton onClick={onClick} loading={loading} />
    </div>
  </>
}


type ExportsGSMType = {
  idPrestation?: number | null | undefined,
  idSite?: number | null | undefined,
  numcli?: string | null | undefined
}
export default function ExportsGSM({
  idPrestation = null,
  idSite = null,
  numcli = null
}: ExportsGSMType) {
  const p = useP();

  const {
    FILER_URL
  } = Constants;


  // Téléchargement du Parc GSM
  const [telechargerParcGSM, { loading: loadingExportParcGSM }] = useLazyQuery(TELECHARGER_EXPORT_PARC_GSM, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      numcli: numcli,
      idPrestation: idPrestation,
      idSite: idSite,
    },
    onCompleted: async (data) => {
      if (data.telechargerParcGsm) {
        const { hash, filename } = data.telechargerParcGsm
        window.open(`${FILER_URL}?hash=${hash}&filename=${filename}`)
      } else {
        toastError(p.t('factures.erreurDocumentNonDisponible'))
      }
    },
  });

  // Téléchargement des CDR
  const [telechargerCdrGSM, { loading: loadingExportCdrGSM }] = useLazyQuery(TELECHARGER_EXPORT_CDR_GSM_PRESTA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      numcli: numcli,
      idPrestation: idPrestation,
      idSite: idSite,
    },
    onCompleted: async (data) => {
      if (data.telechargerCdrGsmPresta) {
        const { hash, filename } = data.telechargerCdrGsmPresta
        window.open(`${FILER_URL}?hash=${hash}&filename=${filename}`)
      } else {
        toastError(p.t('factures.erreurDocumentNonDisponible'))
      }
    },
  });

  // Téléchargement des conso / mois
  const [filterMonth, setFilterMonth] = useState("")
  useEffect(() => {
    const prevMonth = new Date(dayjs().year(), dayjs().month(), 0)
    setFilterMonth(`${dayjs(prevMonth).year()}${dayjs(prevMonth).format('MM')}`) // init au mois précédent
  }, [])

  const [telechargerConso, { loading: loadingExportConso }] = useLazyQuery(TELECHARGER_EXPORT_CONSO, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      numcli: numcli,
      idPrestation: idPrestation,
      idSite: idSite,
      anneeMois: filterMonth
    },
    onCompleted: async (data) => {
      if (data.telechargerConso) {
        const { hash, filename } = data.telechargerConso
        window.open(`${FILER_URL}?hash=${hash}&filename=${filename}`)
      } else {
        toastError(p.t('factures.erreurDocumentNonDisponible'))
      }
    },
  });

  let descriptionParcGsm = p.t('exportsGSM.descriptionParcGsmCompte')
  let descriptionConso = p.t('exportsGSM.descriptionConsoCompte')
  let descriptionCDR = p.t('exportsGSM.descriptionCDRCompte')
  let messageNoDownload = p.t('exportsGSM.messageNoDownloadCompte')
  const filtersPresta: any[] = [];

  if (numcli) {
    descriptionParcGsm = p.t('exportsGSM.descriptionParcGsmContrat')
    descriptionConso = p.t('exportsGSM.descriptionConsoContrat')
    descriptionCDR = p.t('exportsGSM.descriptionCDRContrat')
    messageNoDownload = p.t('exportsGSM.messageNoDownloadContrat')
    filtersPresta.push({
      column: "client.numcli",
      value: numcli
    })
  }
  if (idSite) {
    descriptionParcGsm = p.t('exportsGSM.descriptionParcGsmSite')
    descriptionConso = p.t('exportsGSM.descriptionConsoSite')
    descriptionCDR = p.t('exportsGSM.descriptionCDRSite')
    messageNoDownload = p.t('exportsGSM.messageNoDownloadSite')
    filtersPresta.push({
      column: "site.id",
      value: idSite
    })
  }

  const datasDownloads = [
    {
      label: <><LibelleExportGSM description={descriptionParcGsm} libelle={p.t('exportsGSM.parcGsm')} /></>,
      value: <><DownloadButton onClick={() => telechargerParcGSM()} loading={loadingExportParcGSM} /></>
    },
    {
      label: <><LibelleExportGSM description={descriptionConso} libelle={p.t('exportsGSM.conso')} /></>,
      value: <><DownloadButtonConso onClick={() => telechargerConso()} loading={loadingExportConso} onSelectYearMonth={(value) => setFilterMonth(value)} /></>
    },
    {
      label: <><LibelleExportGSM description={descriptionCDR} libelle={p.t('exportsGSM.cdr')} /></>,
      value: <DownloadButton onClick={() => telechargerCdrGSM()} loading={loadingExportCdrGSM} />
    }
  ]

  const { loading, data } = useQuery(GET_PRESTATIONS, {
    variables: {
      filters: [{
        column: "type",
        value: "GSM"
      },
      ...filtersPresta],
      pagination: {
        limit: 1
      }
    }
  })

  if (loading) {
    return <Spinner />
  }

  return data?.prestations.items.length > 0
    ? <DescriptionList datas={datasDownloads} columnWidthClassName="w-[80%]" bordered />
    : <div className="text-gray-600 text-sm font-medium">{messageNoDownload}</div>

}